<template>
    <div class="eatXfbmupdown">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                @saveClick="saveData()"  
                @outputClick="exportExcel()" 
                @refreshClick="loadData()" 
                @goBackClick="$router.back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        node-key="id"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table">
                    <div class="filter-box">
                        <div class="from-box">
                            <div class="lable-txt">POS状态:</div>
                            <div class="from-input" style="width: 180px;">
                                <el-select v-model="posStatus">
                                    <el-option :value="0" label="全部"></el-option>
                                    <el-option :value="1" label="停售中"></el-option>
                                    <el-option :value="2" label="可售中"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="from-box">
                            <div class="lable-txt">线上状态:</div>
                            <div class="from-input" style="width: 180px;">
                                <el-select v-model="onlineStatus">
                                    <el-option :value="0" label="全部"></el-option>
                                    <el-option :value="1" label="下架中"></el-option>
                                    <el-option :value="2" label="上架中"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                            ref="tableEl"
                            border
                            highlight-current-row
                            @selection-change="selectFood"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageTableData"
                        >
                            <el-table-column label="选择" type="selection" width="40" ></el-table-column>
                            <el-table-column prop="EAT_XFCODE" label="菜品编码" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="EAT_XFNAME" label="菜品名称" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="EAT_XFPRICE" label="售价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column prop="EAT_PARPRICE" label="会员价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column prop="EAT_KINDNAME" label="上级分类" min-width="80" ></el-table-column>
                            <el-table-column min-width="100" label="POS 可售/停售">
                                <template #header>
                                    <el-dropdown trigger="click" :max-height="100">
                                        <span class="el-dropdown-link" >
                                        POS 可售/停售<span class="batch" >[批量]</span>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click="batch('YX',true)">可售</el-dropdown-item>
                                                <el-dropdown-item @click="batch('YX',false)">停售</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                                <template #default="scope">
                                    <template v-if="scope.isExcel">{{scope.row.YX?"可售":"停售"}}</template>
                                    <el-switch v-else size="large" v-model="editFoodList[scope.row.EAT_XFBMID].YX" @click="rowSelect(scope.row)" inline-prompt inactive-color="#8A8A8A" :width="48" active-text="可售" inactive-text="停售"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100" label="线上 上架/下架">
                                <template #header>
                                    <el-dropdown trigger="click" :max-height="100">
                                        <span class="el-dropdown-link" >
                                        线上 上架/下架<span class="batch" >[批量]</span>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click="batch('IS_MOVE',false)">上架</el-dropdown-item>
                                                <el-dropdown-item @click="batch('IS_MOVE',true)">下架</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                                <template #default="scope">
                                    <template v-if="scope.isExcel">{{scope.row.IS_MOVE?"下架":"上架"}}</template>
                                    <el-switch v-else size="large" v-model="editFoodList[scope.row.EAT_XFBMID].IS_MOVE" @click="rowSelect(scope.row)" inline-prompt inactive-color="#8A8A8A" :width="48"  
                                    :active-value="false"
                                    :inactive-value="true"
                                    active-text="上架" inactive-text="下架"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column prop="LASTUPDATED_USER" label="最后发布人" min-width="100" align="left"></el-table-column> 
                            <el-table-column prop="LASTUPDATED_TIME" label="最后发布时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                        </el-table>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                            @keydown.enter="onEnter()"
                        ></key-board-input>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"eatXfbmupdown",
    data() {
        let pageSize= this.$cacheData.get("eatXfbmupdown_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            foodMainCategory:[],
            tablePageIndex:0,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //pos状态
            posStatus:0,
            //线上状态
            onlineStatus:0,
            //菜品上下架数据
            foodDoorList:[],
            /**勾选要修改的数据 */
            checkFoods:[],
            /**修改的数据 */
            editFoodList:{}
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        label:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                        SubCategory_ID:sub.SubCategory_ID,
                    })
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        label:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            let posFoods=this.foodDoorList;
            if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    foods=posFoods.filter(t=>category.FoodSubCategorys.some(it=>it.SubCategory_ID==t.EAT_KINDID));
                }
                posFoods= foods;
            }else if(this.currentNode){
                posFoods=posFoods.filter(t=>t.EAT_KINDID==this.currentNode.id);
            }
            return posFoods;
        },
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.EAT_XFCODE||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.EAT_XFNAME||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.PINYIN||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.posStatus>0){
                let YX=this.posStatus!=1;
                data=data.filter(it=>it.YX==YX);
            }
            if(this.onlineStatus>0){
                let IS_MOVE=this.onlineStatus==1;
                data=data.filter(it=>it.IS_MOVE==IS_MOVE);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.filterData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatBindCard_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        },
        pageTableData:{
            handler(){
                let list={};
                this.pageTableData.forEach(it=>{
                    list[it.EAT_XFBMID]={YX:it.YX,IS_MOVE:it.IS_MOVE};
                });
                this.editFoodList=list;
            },
            immediate:true,
        },
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetFoodUpDown", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    let data=d.ResponseBody;
                    this.setMainCategory(data);
                    
                    this.foodDoorList=data;
                    this.foodDoorList.forEach(it=>{
                        it.newYx=it.YX;
                        it.newIsMove=it.IS_MOVE;
                    })
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        setMainCategory(data){
            this.currentNode=null;
            let mainCategory=[];
            let mainKey={};
            data.forEach(it=>{
                if(!mainKey[it.MainCategory_ID]){
                    mainKey[it.MainCategory_ID]={
                        MainCategory_ID:it.MainCategory_ID,
                        MainCategory_Code:it.MainCategory_Code,
                        MainCategory_Name:it.MainCategory_Name,
                        FoodSubCategorys:[],
                        subKey:{}
                    };
                    mainCategory.push(mainKey[it.MainCategory_ID]);
                }

                if(!mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]){
                    mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]={
                        SubCategory_ID:it.EAT_KINDID,
                        SubCategory_Code:it.EAT_KINDCODE,
                        SubCategory_Name:it.EAT_KINDNAME
                    }
                    mainKey[it.MainCategory_ID].FoodSubCategorys.push(mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]);
                }
            })
            this.foodMainCategory=mainCategory;
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        /**修改时 勾选行 */
        rowSelect(row){
            console.log(row)
            this.$refs.tableEl?.toggleRowSelection(row,true);
        },
        batch(key,newVal){
            this.pageTableData.forEach(it=>{
                this.editFoodList[it.EAT_XFBMID][key]=newVal;
                this.rowSelect(it);
            })
        },
        /**保存修改 */
        saveData(){
            let EatXfbmList=[];
            this.checkFoods?.forEach(t=>{
                let editInfo=this.editFoodList[t.EAT_XFBMID];
                if(editInfo){
                    EatXfbmList.push({
                        EAT_XFBMID:t.EAT_XFBMID,
                        YX:editInfo.YX,
                        IS_MOVE:editInfo.IS_MOVE
                    });
                }
            })
            if(EatXfbmList.length==0){
                this.$message.warning('请勾选需要保存的数据');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                Operator_Name:userInfo?.Login_Name,
                list:EatXfbmList
            }
            this.$httpAES.post("Bestech.CloudPos.SaveFoodUpDown",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    d.ResponseBody?.forEach(it=>{
                        this.foodDoorList.some(food=>{
                            if(food.EAT_XFBMID==it.EAT_XFBMID){
                                food.YX=it.YX;
                                food.IS_MOVE=it.IS_MOVE;
                                food.LASTUPDATED_USER=it.LASTUPDATED_USER;
                                food.LASTUPDATED_TIME=it.LASTUPDATED_TIME;
                                return true;
                            }
                            return false;
                        })
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"菜品上下架",
                list:this.filterData
            })
        },
    }
}
</script>

<style lang="scss">
@import './eatXfbmupdown.scss'
</style>